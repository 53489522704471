<template>
  <div>
    <!-- app bar -->
    <AppBar />
    <div>
      <v-tabs-items v-model="currentStep">
        <v-tab-item
          v-for="step in steps"
          :key="step.id"
          :value="step.id">
          <v-card flat>
            <v-card-text>
              <div class="step-image-container">
                <v-img
                  class="rounded-lg step-image"
                  :src="step.image"
                  :alt="step.title"
                  contain
                    height="35vh" />
              </div>
              <div class="step-title mt-10 mx-2">
                {{ step.title }}
              </div>
              <div class="step-description mt-8 mx-2">
                <p
                  v-for="(paragraph, i) in step.description"
                  :key="i">
                  {{ paragraph }}
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-row
        class="col-12 dot-steps"
        justify="center"
        align="center"
        no-gutters>
        <div
          v-for="step in steps"
          :key="step.id"
          @click="currentStep = step.id"
          class="mr-3"
          :class="{
            'dot-inactive': step.id != currentStep,
            'dot-active': step.id == currentStep,
          }"></div>
      </v-row>
      <v-btn
        fab
        class="next-step-button"
        color="primaryColor"
        @click="nextStep()"
        ><v-icon color="black">mdi-arrow-right</v-icon></v-btn
      >
    </div>
  </div>
</template>

<script>
  import TutorialMixin from '@/views/tutorial/tutorial/Tutorial.mixin.vue';
  export default {
    mixins: [TutorialMixin],
  };
</script>

<style lang="css" scoped>
  .step-title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: var(--v-offBlack-base);
  }
  .step-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: var(--v-offBlack-base);
  }
  .step-image-container {
    width: 100%;
    display: grid;
  }
  .step-image {
    place-self: center;
    display: block;
    width: 232px;
    height: 232px;
  }
  .dot-inactive {
    height: 8px;
    width: 8px;
    /* background-color: #F8F8F8; */
    background-color: #393939;
    border-radius: 50%;
    display: inline-block;
  }
  .dot-active {
    height: 12px;
    width: 12px;
    background-color: var(--v-primaryColor-base);
    border-radius: 50%;
    display: inline-block;
  }
  .dot-steps {
    position: absolute;
    bottom: 6vh;
  }
  .next-step-button {
    position: absolute;
    bottom: 5vh;
    right: 6vw;
  }
</style>
